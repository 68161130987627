import { Carousel, Container } from "react-bootstrap";
import "./Projects.css";

function Projects(): JSX.Element {
  return (
    <Container fluid className="Projects-Section">
      <Carousel>
        <Carousel.Item>
          <a
            href="https://niv-vacation-project.netlify.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="d-block w-100"
              src="https://user-images.githubusercontent.com/98215470/196794768-0929d6bb-4273-4310-8982-121da88bd5da.png"
              alt=""
            />
          </a>
          <Carousel.Caption className="Projects-Caption">
            <h3>Vacations Project</h3>
            <p>
              Vacation Project - Full Stack Web App (MySQL, Node.js and React)
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <a
            href="https://niv-shopping-online.netlify.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="d-block w-100"
              src="https://user-images.githubusercontent.com/98215470/211048485-b788555f-ac51-4d95-9eb2-dff20a9c76dd.png"
              alt=""
            />
          </a>
          <Carousel.Caption className="Projects-Caption">
            <h3>Shopping Online Project</h3>
            <p>
              Shopping Online Project - Full Stack Web App (MongoDB, Node.js and
              Angular)
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
}

export default Projects;
